#results-table > table {
    width: 100%;
 }
 
 #results-table th {
     text-align: left;
     padding: 10px;
     color: white;
     background: #56648A;
 }
 
 #results-table td {
     border-bottom: 1px solid #E1E6F2;
     padding: 10px;
     white-space: nowrap;
     cursor: pointer;
 }
 
 ul#profile-details {
     padding: 0px;
     list-style:none;
     margin-bottom: 0px;
     text-align: left;
     color: #56648A;
     font-size: 14px;
 }
 
 ul#profile-details > li {
     margin-bottom: 10px;
     padding-bottom: 5px;
     display: flex;
     border-bottom: 1px solid #E0E4ED;
     align-items: center;
 }
 
 ul#profile-details > li > svg {
     margin-right: 8px;
     font-size: 10px;
 }
 
 .text-danger {
     color: red;
 }
 
 ul#mentor-onboarding {
     padding: 0px;
     list-style: none;
     margin-bottom: 0px;
 }
 
 ul#mentor-onboarding > li {
     padding: 5px 0px;
 }
 
 ul#mentor-onboarding > li > svg {
     margin-right: 10px;
 }
 
 .display-block {
     display: block;
 }
 
 .white-space-no-wrap {
     white-space: nowrap;
 }