@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://use.typekit.net/ydp4knr.css");

:root {
  --fontFamilySofiaPro: sofia-pro, sans-serif;
  --fontStyle: normal;
  --fontWeightExtraLight: 200;
  --fontWeightLight: 300;
  --fontWeightMedium: 500;
  --fontWeightBold: 700;
  --fontWeightBlack: 900;
}

/* AUTH PAGES */

#auth-page {
  align-items: center;
}

#auth-page > div:first-child {
  z-index: 2;
}

.auth-registration {
  position: relative;
}

#registration-panel {
  background: #fff;
  padding: 20px 40px;
  box-shadow: 0px 8px 29px rgba(8, 51, 117, 0.08);
  border-radius: 12px;
}

#registration-panel h1 {
  color: #29345c;
}

#registration-panel p {
  color: #56648a;
  font-size: 14px;
}

/* GENERAL */

a {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

h3 {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightMedium);
  font-style: var(--fontStyle);
  font-size: 20px;
  line-height: 28px;
  color: #29345c;
  margin: 0px;
}

h6 {
  color: #293961;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
}

body {
  font-family: var(--fontFamilySofiaPro);
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.active-sidebar-item {
  background: red;
}

a.btn {
  display: block;
  background: #ffa928;
  border-radius: 4px;
  white-space: nowrap;
  font-family: var(--fontFamilySofiaPro);
  font-style: var(--fontStyle);
  font-size: 14px;
  padding: 10px 20px;
  line-height: 22px;
  color: white;
  text-align: center;
  width: fit-content;
  border: none;
  text-decoration: none;
  height: 42px;
  cursor: pointer;
}

.btn.btn-primary {
  background: #0c46db;
}

/* MODALS */

.react-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: auto;
  outline: none;
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.react-modal .modal-dialog {
  width: 480px;
}

.react-modal .modal-dialog .modal-dialog-sm {
  width: 405px;
}

.modal-content {
  padding: 40px;
}

/* TRAINING MODULES */

#module-body h1 {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightMedium);
  font-style: var(--fontStyle);
  font-size: 32px;
  line-height: 45px;
  margin: 0px;
}

#module-body h2 {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontStyle);
  font-size: 20px;
  line-height: 32px;
  color: #8692b4;
  margin: 0px;
}

#module-body h3 {
  color: #29345c;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}

#module-body p,
#module-body li {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightLight);
  color: #56648a;
  font-size: 16px;
  line-height: 27px;
}

#module-body strong {
  font-family: var(--fontFamilySofiaPro);
}

#module-body li {
  margin-bottom: 10px;
}

/* MAIN DASHBOARD */

a.btn {
  display: block;
  background: #ffa928;
  border-radius: 4px;
  white-space: nowrap;
  font-family: var(--fontFamilySofiaPro);
  font-style: var(--fontStyle);
  font-size: 14px;
  padding: 10px 20px;
  line-height: 22px;
  color: white;
  text-align: center;
  width: fit-content;
  border: none;
  text-decoration: none;
  height: 42px;
  cursor: pointer;
}

.btn.btn-primary {
  background: #0c46db;
}

.btn.btn-lg {
  font-size: 16px;
  padding: 15px;
  height: 52px;
}

.btn.btn-sm {
  font-size: 14px;
  padding: 8px 14px;
  height: 38px;
}

a.btn.btn-outline {
  border: 1px solid #0c46db;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  color: #0c46db;
}

a.btn.btn-default {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  color: #ffffff;
}

a.btn.btn-block {
  width: 100%;
}

html,
body,
#root,
#root > div:not(.auth-registration):not(.Toastify) {
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-family: var(--fontFamilySofiaPro);
  background-color: #f9fafd;
}

#dashboard {
  display: flex;
  flex-direction: row;
}

#content {
  width: 100%;
  z-index: 98;
}

#alerts {
  margin-left: 264px;
  margin-bottom: -30px;
  position: relative;
  z-index: -1;
}

#page,
#page-skinny {
  margin-left: 264px;
}

#page {
  padding: 100px 60px 20px 60px;
}

#page-skinny {
  padding: 60px 200px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.text-decoration-none {
  text-decoration: none;
}

h3 {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightMedium);
  font-style: var(--fontStyle);
  font-size: 20px;
  line-height: 28px;
  color: #29345c;
  margin: 0px;
}

h5 {
  color: #56648a;
  font-size: 16px;
  line-height: 28px;
}

h6 {
  color: #293961;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
}

button > a {
  color: white;
  text-decoration: none;
}

#step-body h1 {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightMedium);
  font-style: var(--fontStyle);
  font-size: 32px;
  line-height: 45px;
  margin: 0px;
}

#step-body h2 {
  font-family: var(--fontFamilySofiaPro);
  font-size: 20px;
  line-height: 32px;
  color: #8692b4;
  margin: 0px;
}

#step-body h3 {
  font-family: var(--fontFamilySofiaPro);
  font-size: 20px;
  line-height: 27px;
  color: #29345c;
  margin-bottom: 10px;
  margin-top: 30px;
}

#step-body h4 {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightMedium);
  font-style: var(--fontStyle);
  font-size: 16px;
  line-height: 22px;
  color: #29345c;
  margin-bottom: 10px;
  margin-top: 20px;
}

#step-body p,
#step-body li {
  font-family: var(--fontFamilySofiaPro);
  color: #56648a;
  font-size: 16px;
  line-height: 27px;
}

#step-body strong {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightBlack);
}

#step-body .medium {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightMedium);
  font-style: var(--fontStyle);
}

#step-body .strong {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightBold);
}

#step-body .stronger {
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightBlack);
}

#step-body li {
  margin-bottom: 10px;
}

#step-body a {
  border-bottom: 1px dashed -webkit-link;
  text-decoration: none;
}

.step-img {
  width: 100%;
  border-radius: 8px;
}

.step-attachment {
  background: #f4f7fd;
  border: 1px solid #dee4ef;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
  display: flex;
}

.step-attachment a {
  border-bottom: none !important;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.step-attachment .attachment-icon {
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 50%;
  background: #0c46db;
  margin-right: 15px;
}

.step-attachment .attachment-icon > img {
  width: 24px;
  height: auto;
}

.step-attachment h5 {
  margin: 0px;
  color: #0c46db;
  font-family: var(--fontFamilySofiaPro);
  font-weight: var(--fontWeightMedium);
  font-style: var(--fontStyle);
  font-size: 14px;
  line-height: 20px;
}

.step-attachment span {
  color: #56648a;
  font-size: 14px;
  line-height: 22px;
}

.step-attachment .icon {
  background: #0c46db;
}

@media only screen and (max-width: 1200px) {
  #page,
  #page-skinny {
    padding: 60px 20px;
  }
}

@media only screen and (max-width: 768px) {
  #page,
  #page-skinny {
    margin-left: 0px;
    padding: 60px 20px;
  }

  #alerts {
    margin-left: 0px;
    position: relative;
  }

  .hidden-mobile {
    display: none;
  }

  .mb-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .react-modal .modal-dialog {
    width: 100%;
  }
}

.width-100 {
  width: 100%;
}

#college-slideshow {
  margin: -70px -60px 20px -60px;
  overflow: hidden;
}

#college-slideshow > .col-md-12 {
  padding: 0px;
}

#college-slideshow-nav {
  bottom: 25px;
}

#college-slideshow-nav > li {
  width: 43px;
  height: 4px;
}

#college-slideshow-nav > li.slick-active {
  background: #ffffff;
  border-radius: 30px;
}

.z-index-1 {
  z-index: 1;
}

.z-index-10 {
  z-index: 10;
}

.video-embed-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

textarea::placeholder {
  opacity: 0.6;
}

.resize-none {
  resize: none;
}

.text-danger {
  color: red;
}

.text-success {
  color: green;
}

/* SHOULD BE COVERED */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* DDATEPICKER */
.react-datepicker-wrapper {
  width: 100%;
}
